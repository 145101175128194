body.active-modal {
    overflow-y: hidden;
}

.donate-btn {
   cursor: pointer;
}
a i {
    padding-right: 2px;
  }

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 800px;
    width: 70%;
    min-width: 300px;
    max-height: 600px;
    height: 100%;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 11px;
    padding: 5px 8px;
    font-size: 1rem;
}
