#duas {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    height: 100%;
    width: 100%;
    background-color: #e6e6e6;
}

.category-container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 20px;
    margin-bottom: 40px;
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2,minmax(300px,1fr));
    /* border: 1px solid #ccc; */
    gap: 1rem;
    width: 90%;
  }
  
.section-title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5em;
    color: #170827;
    margin-bottom: 10px;
    text-decoration: underline #ceb378 5px;
  }

.dua-card {
    aspect-ratio: 16 / 9;
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: white;
}

.dua-card div img {
    width: 100%;
    height: 100%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    object-fit: cover;
}
.dua-card p {
    text-align: center;
    color: black;
    font-size: 1.1rem;
}

.dua-card:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
}


@media (max-width: 768px) {
    .category-container {
        grid-template-columns: repeat(auto-fit,minmax(250px, 1fr));
        gap: 1rem;
    }
}